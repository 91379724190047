import http from '@/utils/http';

const apiList = {
  getPortrayalList: `/account/memberInfo/listPortrayalPage`, // 画像列表
  getActivityDetail: `/account/activityAttendeeAction/totByIsmartekIdSum`, // 活跃度详情
  getActivityInfo: `/account/activityAttendeeAction/getByIsmartekIdInfo`, // 查询单个会员活跃度详情
  getMaturityInfo: `/account/memberMaturityInfo/getIsmartekIdMaturity`, // 查询单个会员活跃度详情
};

export default {
  pageUrl: '/account/memberInfo/listPortrayalPage',
  getMaturity(code, params) {
    return http.get('/account/memberMaturityInfo/getIsmartekIdMaturity', {
      params,
      code,
    });
  },

  getActivity(code, params) {
    return http.get('/account/activityAttendeeAction/getByIsmartekIdInfo', {
      params,
      code,
    });
  },

  getActivityDetail(data = {}) {
    return http({
      method: 'GET',
      url: apiList.getActivityDetail,
      data,
    });
  },
};
